/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { Button } from 'reactstrap';
import { FetchPolicy, NotificationType } from '../../../enums/general';
import UserGraphql from '../../../servicesapollo/userGraphql';

import useCustomLocalStorage from '../../hooks/useCustomLocalStorage';
import AppContext from '../../../config/appContext';

import './login.css';
import HeaderTitle from './headerTitle';
import FooterCopyRight from './footerCopyRight';
import useMessageHelper from '../../../helpers/messageHelper';

function LoginV2() {
    const { showNotification, languageTitles, setLanguage } = useContext(AppContext);
    const { getErrorMessage } = useMessageHelper();
    const { setToken, setUserAuth, clearLocalStorage } = useCustomLocalStorage();

    const initRecord = {
        email: '',
        password: '',
        rememberMe: false,
    };

    const [record, setRecord] = useState(initRecord);

    const [loginUser, { loading }] = useLazyQuery(UserGraphql.LOGIN_USER, {
        onCompleted: (res) => {
            if (res.authenticateUser) {
                const {
                    success, data, error, message,
                } = res.authenticateUser;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(error?.length > 0 ? error : languageTitles.errorWhenAuthenticate, NotificationType.DANGER);
                    return;
                }

                setToken(data.Token);
                setUserAuth({
                    UserId: data.UserId,
                    Email: data.Email,
                });
                window.location.href = '/#/home';
                window.location.reload();
            }
        },
        onError: (serverError) => {
            showNotification(getErrorMessage(serverError), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onSave = (e) => {
        e.preventDefault();

        loginUser({
            variables: {
                ux: record.email,
                px: record.password,
            },
        });
    };

    const handleInputChange = (e) => {
        const { target } = e;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        setRecord({
            ...record,
            [target.name]: value,
        });
    };

    useEffect(() => {
        clearLocalStorage();
    }, []);

    return (
        <>
            <div className="login login-v2 fw-bold">
                <div className="login-cover">
                    <div className="login-cover-img" style={{ backgroundImage: 'url(/assets/img/login-bg/quebec_city.jpg)' }} />
                    <div className="login-cover-bg" />
                </div>

                <div className="login-container">
                    <HeaderTitle />

                    <div className="login-content">
                        <form onSubmit={onSave}>
                            <div className="form-floating mb-20px">
                                <input
                                    type="email"
                                    className="form-control fs-13px h-45px border-0"
                                    name="email"
                                    id="emailAddress"
                                    value={record.email}
                                    onChange={handleInputChange}
                                    required
                                />
                                <label htmlFor="emailAddress" className="d-flex align-items-center text-gray-600 fs-13px">{languageTitles.loginEmail}</label>
                            </div>
                            <div className="form-floating mb-20px">
                                <input
                                    type="password"
                                    className="form-control fs-13px h-45px border-0"
                                    name="password"
                                    id="password"
                                    value={record.password}
                                    onChange={handleInputChange}
                                    required
                                />
                                <label htmlFor="password" className="d-flex align-items-center text-gray-600 fs-13px">{languageTitles.loginPassword}</label>
                            </div>
                            <div className="mb-20px">
                                <Button
                                    color="yellow"
                                    className="d-block w-100 h-45px btn-lg"
                                    disabled={loading}
                                    type="submit"
                                >
                                    {
                                        loading
                                            ? <i className="fa fa-spinner fa-spin fa-fw" /> : ''
                                    }
                                    {
                                        loading
                                            ? languageTitles.loginBtnAuthLabel : languageTitles.loginBtnLabel
                                    }
                                </Button>
                            </div>
                            <div className="text-grey-darker mb-20px">
                                {languageTitles.loginForgotPasswordLabel}
                                <Link to="/forgotpassword">
                                    {languageTitles.loginForgotPasswordLinkLabel}
                                </Link>
                            </div>
                            <div className="text-grey-darker mb-20px">
                                {languageTitles.loginNeedUser}
                                <Link to="/register">
                                    {languageTitles.loginForgotPasswordLinkLabel}
                                </Link>
                            </div>
                            <div className="text-grey-darker  mb-20px">
                                {languageTitles.loginHelpValidateAccount}
                                <Link to="/validaccount">{languageTitles.loginForgotPasswordLinkLabel}</Link>
                            </div>
                            <FooterCopyRight />
                            <div className="news-caption mt-20px text-center mb-20px">
                                <p>{languageTitles.downloadAppTitle}</p>
                                <a target="_blank" className="text-decoration-none" href="https://play.google.com/store/apps/details?id=com.tsf.tsfapp2" rel="noreferrer noopener">
                                    <img src="/assets/img/playstore.png" alt="google play" />
                                </a>
                                <a target="_blank" className="text-decoration-none" href="https://apps.apple.com/us/app/tsf/id6689493339" rel="noreferrer noopener">
                                    <img src="/assets/img/ios.png" alt="app store" style={{ height: 36, marginLeft: 20 }} />
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="login-bg-list clearfix">
                <div className="login-bg-list-item">
                    <span onClick={() => setLanguage('Es')} className="login-bg-list-link" style={{ backgroundImage: 'url(../assets/img/spain-flag.svg)' }} />
                </div>
                <div className="login-bg-list-item">
                    <span onClick={() => setLanguage('En')} className="login-bg-list-link" style={{ backgroundImage: 'url(../assets/img/usa-flag.svg)' }} />
                </div>
                <div className="login-bg-list-item">
                    <span onClick={() => setLanguage('Fr')} className="login-bg-list-link" style={{ backgroundImage: 'url(../assets/img/france-flag.svg)' }} />
                </div>
            </div>
        </>
    );
}

export default LoginV2;
