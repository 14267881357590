const Lang = {
    // general labels
    searchApplication: 'Buscar Aplicacion',
    notificationLabel: 'Notificaciones',
    notificationViewMore: 'Ver mas...',
    nightMode: 'Oscuro',
    lightMode: 'Claro',
    editProfileTitle: 'Actualizar Perfil',
    closeAppTitle: 'Salir de la aplicacion',
    writeToSearch: 'Escriba aqui para buscar...',
    loadingRecords: 'Cargando registros...',
    loadingRecord: 'Cargando registro...',
    recordNotFound: 'No se encontro ningun registro con este id',

    // menu sidebar
    menuHome: 'Inicio',
    menuApplicants: 'Candidatos',
    menuAddCV: 'Agregar Curriculum',
    menuEditCV: 'Editar Curriculum',
    menuAdminFases: 'Administrar fases',
    menuCompanies: 'Compañias',
    menuUsers: 'Usuarios',
    menuEmailTemplates: 'Plantillas de correos',
    menuJobs: 'Puestos de trabajo',
    menuPyscoTest: 'Pruebas Psicometricas',
    menuDocumentShared: 'Documentos compartidos',
    menuAccess: 'Permisos',
    menuManageDocuments: 'Enviar documentos',
    menuEditCompany: 'Información de la Compañia',
    menuEmails: 'Correos',
    menuEditCompanyJobPositions: 'Puestos a cubrir',
    menuEditCompanyEmployees: 'Fuerza de trabajo dentro de la empresa',
    menuEditCompanyFiles: 'Publicaciones de trabajo',

    // Dashboard
    dashboardTotalUpdates: 'Actualizados en los ultimos 60 dias',
    dashboardTotal: 'Total de candidatos',
    dashboardTotalToday: 'Solicitudes de hoy',
    dashboardGraphTitle: 'Solicitudes por mes y año',
    dashboardGraphLegend: 'Candidatos',
    dashboardGrahpBarGenderTitle: 'Candidatos por Genero',
    dashboardPsycoTestTableLabel: 'Pruebas psicométricas',
    dashboardPsycoTestNoRecordsFound: 'No hay pruebas habilitadas',
    dashboardPsycoTestColumnDetail: 'Detalle',
    dashboardPsycoTestColumnAction: 'Acción',
    dashboardPsycoTestColumnStatus: 'Estado',
    dashboardPsycoTestEnableLegend: 'Se ha habilitado para realizar la ',
    dashboardPsycoTestUnableTest: 'La prueba ya no se encuentra disponible ',
    dashboardPsycoTestStartBtn: 'Iniciar',
    dashboardPsycoTestDISC: 'Prueba 1',
    dashboardPsycoTestTerman: 'Prueba 2',
    dashboardProfileChartComplete: 'Perfil completado',
    dashboardProfileChartPendingPoints: 'Puntos pendientes',
    dashboardProfileChartCompleteLabel: '% Completado',
    dashboardProfileChartPendingLabel: '% Pendiente',
    dashboardLastLoginTitle: 'Ultimos accesos',
    dashboardlinkTitle: 'Acceder',
    dashboardCompanyWelcomeMessage: `<p>Querido Cliente,</p>
    <p>
    Es un placer colaborar contigo para la realización de tu proyecto de reclutamiento.
    </p>
    <p>
    A través de este cuestionario, TSF recopilará toda la información necesaria para el análisis del expediente y orientar adecuadamente a los candidatos que se incorporarán a su equipo.
    </p>
    <p>
    También nos aseguraremos de tener todos los datos necesarios para satisfacer el Programa de Trabajadores Extranjeros Temporales a través del cual sus trabajadores serán contratado.
    </p>
    <p>
    No dude en ponerse en contacto con un miembro del equipo de TSF para cualquier pregunta; Será un placer guiarte.
    </p>`,
    dashboardCompanyStartButton: 'Comenzar',

    // candidates
    candidatesTableColumnId: 'Id',
    candidatesTableColumnImage: 'Imagen',
    candidatesTableColumnName: 'Nombre',
    candidatesTableColumnEmail: 'Correo Electronico',
    candidatesTableColumnAge: 'Edad',
    candidatesTableColumnProfession: 'Profesion',
    candidatesTableColumnJobsApplicant: 'Puesto que aplica',
    candidatesTableColumnActions: 'Opciones',
    candidatesEditBtnTitle: 'Actualizar datos del candidato',
    candidatesExportBtnTitle: 'Exportar a PDF',
    candidatesSendEmailBtnTitle: 'Enviar un correo',
    candidatesMessageBtnTitle: 'Agregar un comentario',
    candidatesWhatsappBtnTitle: 'Escribir en whatsapp',
    candidatesProcessApplicationBtnTitle: 'Procesar aplicación',
    candidatesEnableTermanBtnTitle: 'Habilitar prueba Terman',
    candidatesEnableDISCBtnTitle: 'Habilitar prueba DISC',
    candidatesActionDropdownLabel: 'Acciones',
    candidatesActionDropdownAddFase: 'Agregar a una Fase',
    candidatesActionDropdownAddLabel: 'Agregar un color de etiqueta',
    candidatesActionDropdownAsignCompany: 'Asignar a una compañia',
    candidatesActionDropdownSentEmail: 'Enviar correo',
    candidatesActionButtonsMoreFilter: 'Aplicar otros filtros',
    candidatesActionButtonsExportPDF: 'Exportar lista a pdf',
    candidatesActionButtonsExportExcel: 'Exportar lista a excel',
    candidatesActionButtonsRefresh: 'Refrescar datos',
    candidatesActionButtonsRestart: 'Limpiar Filtros',
    candidatesActionButtonsSearch: 'Buscar',
    candidatesFiltersSortBy: 'Ordenar Por',
    candidatesFiltersSortByNameASC: 'Nombre ASC',
    candidatesFiltersSortByNameDESC: 'Nombre DESC',
    candidatesFiltersSortByLastNameASC: 'Apellido ASC',
    candidatesFiltersSortByLastNameDESC: 'Apellido DESC',
    candidatesFiltersSortByCountryASC: 'Pais ASC',
    candidatesFiltersSortByCountryDESC: 'Pais DESC',
    candidatesFiltersSortByAgeASC: 'Edad ASC',
    candidatesFiltersSortByAgeDESC: 'Edad DESC',
    candidatesFiltersWithoutJob: 'Candidatos sin trabajo',
    candidatesFiltersAgeLabel: 'Edad',
    candidatesFiltersSelectDates: 'Seleccionar Fechas',
    candidatesFiltersGender: 'Genero',
    candidatesFiltersProfesion: 'Profesión',
    candidatesFiltersJobs: 'Puestos',
    candidatesFiltersCountry: 'Pais',

    candidateTabProfile: 'Perfil',
    candidateTabStudies: 'Estudios',
    candidateTabExperience: 'Experiencias',
    candidateTabFiles: 'Archivos',
    candidateTabJob: 'Habilidades del puesto',
    candidateJobPosition: 'Puestos al que aplica',
    candidateName: 'Nombres',
    candidateLastName: 'Apellidos',
    candidateBirthday: 'Fecha de nacimiento',
    candidateMaritalStatus: 'Estado civil',
    candidateGender: 'Genero',
    candidateIdentification: 'Identificación',
    candidateHasLicence: 'Tiene licencia de conducir',
    candidateCategoryLicence: 'Categoria de la licencia',
    candidateProfession: 'Profesión',
    candidateEnglishLevel: 'Nivel de Ingles',
    candidateFrenchLevel: 'Nivel de Frances',
    candidateChildrens: 'Tiene hijos',
    candidateChildrensCount: 'Número de hij@s',
    candidateCountryBorn: 'Pais de nacimiento',
    candidateCityBorn: 'Ciudad de nacimiento',
    candidateDepartmentBorn: 'Departamento o Municipio de nacimiento',
    candidateCountryResident: 'Pais de residencia',
    candidateCityResident: 'Ciudad de residencia',
    candidateDepartmentResident: 'Departamento o Municipio de residencia',
    candidateAddress: 'Dirección',
    candidateAddress2: 'Dirección 2',
    candidateCellphone: 'Teléfono celular',
    candidateHomePhone: 'Teléfono casa',
    candidateEmail: 'Correo electrónico',
    candidateHasPassport: 'Tiene Pasaporte',
    candidatePassport: 'Número de Pasaporte',
    candidatePassportExpirationDate: 'Fecha expiración de Pasaporte',
    candidateHasTravel: 'Indicar los ultimos 2 viajes realizados',
    candidateStartDateTravel: 'Fecha Entrada',
    candidateEndDateTravel: 'Fecha Salida',
    candidateCountryTravel: 'Pais',
    candidateAboutMe: 'Experiencia laborales',
    candidateVisaDenied: 'Le han negado la Visa',
    candidateVisaDeniedDate: 'Fecha de negación de la visa',
    candidateVisaDeniedComments: 'Motivos porque se la negaron',
    candidateLiveInOtherCountry: 'Has vivido en otro pais por mas de 6 meses',
    candidateLivedCountry: 'Que pais?',
    candidateLivedCountryDate: 'Que fecha?',
    candidateLivedCountryDatePlaceHolder: 'Fecha en que vivió fuera del pais',
    candidateLivedCountryMigrateStatus: 'Cual era tu estatus migratorio?',
    candidateLivedCountryMigrateStatusPlaceHolder: 'Estatus migratorio',
    candidateLeadSource: 'Como te enteraste de nosotros?',
    candidateIndicationsToFillData: 'Ingrese los datos en orden crónologico escribiendo de la más reciente primero a la más antigua al final.',
    candidateStudiesCenter: 'Centro de estudio',
    candidateStudiesStart: 'Fecha Inicio',
    candidateStudiesEnd: 'Fecha Fin',
    candidateStudiesTitle: 'Titulo obtenido',
    candidateStudiesTitleType: 'Tipo de titulo obtenido',
    candidateExperienceCompany: 'Nombre de la compañia',
    candidateExperienceStart: 'Fecha Inicio',
    candidateExperienceEnd: 'Fecha Fin',
    candidateExperienceJob: 'Cargo',
    candidateExperienceJobDescription: 'Descripción del puesto',
    candidateMessageLabel: 'Comentarios',
    candidateExperienceCompanyLocation: 'Ubicación de la compañía',
    candidateExperienceCompanyLocationPlaceholder: 'Ciudad o Municipalidad',

    // actions
    saveTitle: 'Guardar ',
    savingTitle: 'Guardando...',
    updateTitle: 'Editar ',
    updatingTitle: 'Actualizando...',
    refreshTitle: 'Refrescar ',
    refreshingTitle: 'Refrescando...',
    deleteTitle: 'Eliminar ',
    deletingTitle: 'Eliminando...',
    sendTitle: 'Enviar ',
    sendingTitle: 'Enviando...',
    cancelTitle: 'Cancelar',
    cancelingTitle: 'Cancelando',
    printTitle: 'Imprimir',
    printJobBtnTitle: 'Publicar oferta de trabajo',
    printingTitle: 'Imprimiendo',
    uploadingTitle: 'Subiendo',
    donwloadTitle: 'Descargar',
    chooseTitle: 'Seleccionar',
    closeTitle: 'Cerrar',
    backTitle: 'Regresar',
    removeButton: 'Remover',
    addButton: 'Agregar',
    nextBtn: 'Siguiente',
    previousBtn: 'Anterior',
    clearBtnTitle: 'Limpiar',
    nextSectionPositionsBtn: 'Sección de puestos',
    nextSectionWorkforceBtn: 'Sección Laboral',
    nextSectionJobPostingBtn: 'Sección de Publicaciones',
    notifiqueToCompanyAdminBtn: 'Listo – enviar a TSF',

    // company
    companyAddBtn: 'Agregar compañia',
    companyPhoneLabel: 'Teléfono',
    companyAddress: 'Dirección',
    companyUserAssociatedBtnTitle: 'Candidatos asociados',
    companyEditBtnTitle: 'Editar compañia',
    companyPopupAddTitle: 'Agregar compañia',
    companyPopupEditTitle: 'Actualizando datos de la compañia',
    companyContactName: 'Nombre de la persona de contacto',
    companyPrintFullReport: 'Imprimir reporte completo',

    // users
    usersTableColumnId: 'Id',
    usersTableColumnImage: 'Imagen',
    usersTableColumnName: 'Nombre',
    usersTableColumnEmail: 'Correo Electronico',
    usersTableColumnActions: 'Accciones',
    usersBtnValidateUser: 'Validar usuario',
    usersBtnChangePassword: 'Cambiar contraseña',
    usersActionDropdownValidate: 'Validar usuarios',
    usersActionDropdownDelete: 'Borrar usuarios',
    userChangePasswordTitle: 'Cambio de contraseña',
    userOldPassword: 'Contraseña anterior',
    userNewPassword: 'Nueva contraseña',
    userAssociateUser: 'Asociar usuario',
    userVerifyPassword: 'Verificar contraseña',
    userToken: 'Token',
    userRequestToken: 'Solicitar Token',
    userProfileTitle: 'Actualizando Usuario',
    userImageIndication: 'Nota: La foto debe ser con fondo blanco, presentable y sonriente.',

    // email template
    emailTemplateTitle: 'Titulo de la plantilla',
    emailContentTitle: 'Contenido de la plantilla',

    // psycotest
    psycoTestTableColumnName: 'Nombre',
    psycoTestTableColumnEmail: 'Correo',
    psycoTestTableColumnTest: 'Prueba',
    psycoTestTableColumnActive: 'Activa',
    psycoTestTableColumnExpiration: 'Expiración',
    psycoTestTableColumnStatus: 'Estado',
    psycoTestTableColumnActions: 'Accciones',
    psycoTestBtnAnalize: 'Analizar',

    termanCategory1: 'Información',
    termanCategory2: 'Comprensión',
    termanCategory3: 'Verbales',
    termanCategory4: 'Selección lógica',
    termanCategory5: 'Aritmética',
    termanCategory6: 'Juicio Práctico',
    termanCategory7: 'Analogias',
    termanCategory8: 'Ordenamiento',
    termanCategory9: 'Clasificación',
    termanCategory10: 'Seriación',
    termanChart1Title: 'Evaluación de coeficiente intelectual terman versión TSF',
    termanChart1TotalLabel: 'Total',
    termanChart2Title: 'Preguntas contestadas vs No Contestadas',
    termanChart2Serie1: 'Respuestas Correctas',
    termanChart2Serie2: 'Respuestas Incorrectas',
    termanChart2Serie3: 'No Contestadas',
    termanConclusionPercentageLabel1: 'BAJO',
    termanConclusionPercentageLabel2: 'INFERIOR',
    termanConclusionPercentageLabel3: 'MEDIO',
    termanConclusionPercentageLabel4: 'ALTO',
    termanConclusionPercentageLabel5: 'SUPERIOR',
    termanConclusionPercentageLabel6: 'EXCELENTE',
    termanCandidateName: 'Nombre',
    termanCorrectAnswer: 'Preguntas Correctas',
    termanAveragePercentage: 'Promedio Ponderado',
    termanApplyTo: 'Aspirante a',
    termanTestLabel: '# Prueba',
    termanResultLabel: 'Puntaje',
    termanConclusionLabel: 'Conclusiones',
    termanClassificationLabel: 'Clasificación',

    discChart1Title: 'Evaluación de Prueba DISC',
    discChart1Serie1: 'Dominante',
    discChart1Serie2: 'Influyente',
    discChart1Serie3: 'Estable',
    discChart1Serie4: 'Concienzudo',
    discPatternLabel: 'Patron del',
    discPatternLabel1: 'Emociones',
    discPatternLabel2: 'Meta',
    discPatternLabel3: 'Juzga a los demás por',
    discPatternLabel4: 'Influye en los demás',
    discPatternLabel5: 'Su valor para la',
    discPatternLabel6: 'Abusa de',
    discPatternLabel7: 'Bajo presión',
    discPatternLabel8: 'Teme',
    discPatternLabel9: 'Sería mas eficaz si',

    // documents
    documentShareLegend: 'Archivos compartidos, descarguelos, llenelos y luego subalos aca mismo',
    documentRepositoryTableColumn1: 'Tipo de archivo',
    documentRepositoryTableColumn2: 'Nombre de archivo',
    documentRepositoryTableColumn3: 'Url',
    documentRepositoryTableColumn4: 'Fecha',
    documentRepositoryTableColumn5: 'Acciones',
    documentRepositoryBtnChooseFile: 'Elegir archivo',
    documentRepositoryChooseFileTitle: 'Seleccionar archivos',
    documentRepositoryBtnAddFile: 'Agregar',
    documentRepositoryBtnUploadFiles: 'Subir archivos',
    documentRepositoryBtnUploadingFile: 'Subiendo archivo...',
    documentRepositoryBtnUploadingFiles: 'Subiendo archivos...',

    // sweetalert for delete
    sweetAlertDeleteConfirmBtnText: 'Si, proceder',
    sweetAlertDeleteCancelBtnText: 'Cancelar',
    sweetAlertDeleteTitle: 'Estas seguro?',
    sweetAlertDeleteConfirm: 'El cambio no podra revertirse!',
    // sweetalert to confirm
    sweetAlertConfirmBtnText: 'Continuar',
    sweetAlertCancelBtnText: 'Cancelar',

    // inbox
    mailInboxCreateEmail: 'Redactar',
    mailInboxFolderAll: 'Todos',
    mailInboxFolderUnread: 'Sin leer',
    mailInboxFolderImportant: 'Importantes',
    mailInboxFolderSent: 'Enviados',
    mailInboxMessageLabel: 'Mensajes',
    mailDetailReply: 'Contestar',
    mailDetailFrom: 'De',
    mailDetailTo: 'Para',
    mailSentCopy: 'Copia',
    mailSentBlankCopy: 'Copia oculta',
    mailSelectTemplate: 'Seleccionar plantilla',
    mailSelectFiles: 'Agregar archivos',
    mailSelectFile: 'Agregar archivo',

    // pagination
    pageLabel: 'Página',
    pageSeparator: 'de',
    pageFirst: 'Primera página',
    pageNext: 'Siguiente página',
    pagePrevious: 'Página anterior',
    pageLast: 'Ultima página',

    // company form
    companyTabIdentification: '1 - Identificación del cliente',
    companyTabHistoryRecruitment: '2 - Historial de reclutamiento',
    companyTabConditions: '3 - Condiciones de trabajo y salarios',
    companyName: 'Nombre de la empresa según el registro de empresas',
    companyImageUrl: 'Logo de la empresa',
    mainAddressStreet: 'Dirección Principal',
    mainAddressStreetPlaceHolder: 'Calle y numero de calle',
    mainAddressOffice: 'Oficina',
    mainAddressCity: 'Ciudad',
    mainAddressProvince: 'Provincia',
    mainAddressPostalCode: 'Código Postal',
    isJobSameMainAddress: 'Es el lugar de trabajo el mismo que la direccion principal?',
    jobAddressStreet: 'Dirección',
    jobAddressOffice: 'Oficina',
    jobAddressCity: 'Ciudad',
    jobAddressProvince: 'Provincia',
    jobAddressPostalCode: 'Código Postal',
    haveAnotherJobAddress: 'Deseas agregar otro lugar de trabajo?',
    otherJobAddressStreet: 'Dirección',
    otherJobAddressOffice: 'Oficina',
    otherJobAddressCity: 'Ciudad',
    otherJobAddressProvince: 'Provincia',
    otherJobAddressPostalCode: 'Código Postal',
    coordinatesPhone: 'Número de teléfono principal',
    coordinatesFax: 'Número de fax',
    coordinatesWebsiteLink: 'Sitio web de la empresa',
    contactName: 'Nombre de la persona de contacto principal',
    contactTitle: 'Título',
    contactPhone: 'Número de teléfono',
    contactPoste: 'Poste',
    contactEmail: 'Dirección de correo electrónico',
    hasSecondaryContact: 'Agregar otra persona de contacto',
    secondaryContactName: 'Nombre de la persona de contacto secundaria',
    secondaryContactTitle: 'Título',
    secondaryContactPhone: 'Número de teléfono',
    secondaryContactPoste: 'Poste',
    secondaryContactEmail: 'Dirección de correo electrónico',
    companySigner: 'Quien será el firmante?',
    companySignerText: 'El firmante debe ser el propietario, franquiciado, gerente principal o ejecutivo principal (presidente, vicepresidente, etc.)',
    companySignerEmail: 'Correo electrónico de la persona que firma',
    accountsPayableResponsible: 'Nombre del responsable de cuentas por pagar',
    accountsPayableResponsibleEmail: 'Correo electrónico del responsable de cuentas por pagar',
    companyIsFranchise: 'Es la empresa una franquicia?',
    companyOrganizationType: 'Cuál es el tipo de organización de la empresa?',
    companyFoundationDate: 'Fecha de inicio del negocio',
    companyBusinessNumber: 'Número de negocio CRA',
    companyNEQ: 'Número de empresa de Québec (NEQ)',
    companyRevenueOverflow: 'La compañía ganó más de $ 5 millones en ingresos brutos anuales en su último año fiscal?',
    companyAllowEDSC: 'La empresa recibe apoyo del Programa de Trabajo Compartido de EDSC?',
    companyEDSCDescription: 'Detalles del programa',
    companyJobPositionSyndicate: 'El puesto a cubrir está sindicalizado?',
    companyJobPositionSyndicateName: 'Nombre del sindicato',
    companyJobPositionSyndicateLocal: 'Nombre del convenio colectivo',
    hasInternationalRecruitmentBefore: 'Alguna vez la compañía ha reclutado trabajadores a través del programa de Trabajadores Extranjeros Temporales?',
    hasEmployeeBenefit: '¿La empresa brinda beneficios a los empleados?',
    benefitDisabilityInsurance: 'Seguro de invalidez',
    benefitDentalInsurance: 'Seguro dental',
    benefitPensionPlan: 'Plan de pensiones proporcionado por el empleador',
    benefitMedicalInsurance: 'Seguro médico complementario (medicamentos, atención paramédica, etc.)',
    benefitWorkWear: 'Ropa de trabajo',
    benefitWorkShoes: 'Botas de trabajo',
    benefitVisionGlasses: 'Gafas adaptadas a la vista',
    benefitOther: 'Otro',
    hasAdditionalVacation: 'Ofrece la empresa más vacaciones legales de las estipuladas por las normas laborales?',
    additionalVacationDescription: 'Explique',
    hoursPerWeekAllowed: 'Cuántas horas por semana trabajarán los trabajadores extranjeros?',
    overtimeHoursAllowedAfter: 'Las horas extraordinarias se calculan después de',
    overtimeHoursAllowedAfterDetail: 'Detalles',
    quarterSchedule: 'En qué turno tendrán que trabajar los trabajadores extranjeros?',
    quarterScheduleDetail: 'Detalles',
    salaryPaymentFrecuency: 'Cuál es la frecuencia de pago?',
    hasBonus: '¿La empresa ofrece bonos a sus empleados (turno vespertino, bono de producción u otros)?',
    bonusType: 'Tipo de bono',
    bonus: 'Tipo de bonificación',
    bonusDetail: 'Detalles de bonificación',
    workShift: 'Horario de trabajo',
    workShiftInit: 'Hora de inicio y hora de fin',
    workShiftEnd: 'Hora de finalización',
    workShiftComment: 'Comentarios',
    employeeMustProvideToolsOrEquipment: 'El empleado debe proporcionar herramientas o equiparse a su llegada',
    recruitmentJobPositionTitle: 'Trabajo',
    recruitmentAcceptanceDate: 'Fecha de aceptación',
    recruitmentInCourse: 'En progreso',
    recruitmentSalaryType: 'Salario regular Alto/Bajo',
    recruitmentSimplifiedProcessing: 'Procesamiento simplificado',
    recruitmentSalary: 'Salario actual del trabajador',
    recruitmentComment: 'Comentario',
    recruitmentTableHeader1: 'Aplicaión de evaluación de impacto en el mercado laboral (LMIA)',
    recruitmentTableHeader2: 'Tipo Limia',
    detailLabel: 'Detalles',

    // company jobs
    companyTabIdentificationJob: 'Identificación del puesto a cubrir',
    jobPositionTitle: 'Cual es el título del puesto a cubrir?',
    jobPositionNumber: 'Cuántos trabajadores se requieren para este puesto?',
    salaryPerHour: 'Cuál será el salario por hora disponible para los trabajadores extranjeros?',
    jobDescription: 'Descripción detallada de la tarea',
    jobDescriptionDocumentUrl: 'Adjuntar documento detallado de la tarea',
    miscellaneousConditions: 'Condiciones adicionales',
    employmentStatusId: 'Estatus laboral',
    monthsEmploymentPerYear: 'Indique el número de meses por año',
    educationalLevelId: 'Nivel educacional',
    yearsOfExperience: 'Años de experiencia',

    // company employees
    companyTabEmployeeTitle: 'Plantilla de empleados',
    companyTabEmployeeTab1Title: 'Números de empleados',
    companyTabEmployeeTab2Title: 'Registro de empleos',
    alienEmployeeTitle: '', // 'La empresa contrató trabajadores extranjeros en 2024 o antes? Si sí ó del 25 de mayo al 20 de junio. Cuantos fueron?',
    fullTimeEmployees: 'Empleados a tiempo completo (incluido T.E.T)',
    partTimeEmployees: 'Empleados a tiempo parcial (menos de 30 horas) (incluido T.E.T)',
    temporaryEmployees: 'Trabajador extranjero temporal',
    nationalEmployeeTitle: 'Número total de empleados a NIVEL NACIONAL bajo el número de CRA citado anteriormente',
    localEmployeeTitle: 'Número de empleados SOLO EN EL LUGAR DE TRABAJO',
    localWorkingEmployeeTitle: 'Número total de empleados OCUPANDO EL PUESTO EN EL LUGAR DE TRABAJO',
    otherWorkingEmployeeTitle: 'Número total de empleados EN OTRO LUGAR DE TRABAJO',
    companyEmployeeJobTitle: 'Titulo del puesto',
    temporaryFullTimeEmployees: 'Número de trabajadores extranjeros temporales a tiempo completo',
    temporaryPartTimeEmployees: 'Número de trabajadores extranjeros temporales a tiempo parcial',
    canadianFullTimeEmployees: 'Número de trabajadores canadienses a tiempo completo',
    canadianPartTimeEmployees: 'Número de trabajadores canadienses a tiempo parcial',

    temporaryRegisterEmployees: 'Número de Registros de Empleo emitidos en los últimos 12 meses para trabajadores extranjeros temporales que ocupan el puesto en cuestión',
    canadianRegisterEmployees: 'Número de registros de empleo emitidos en los últimos 12 meses para trabajadores canadienses que ocupan el puesto en cuestión',
    canadianRegisterQuestion: 'Cuántas declaraciones emitidas para',
    registerEmployeesJobTitle: 'Titulo del puesto',
    registerEmployeesBackToSchool: 'Regreso a la escuela',
    registerEmployeesSickness: 'Enfermedad / Lesión',
    registerEmployeesVoluntaryResignation: 'Salida voluntaria',
    registerEmployeesMaternity: 'Maternidad / Paternidad / Parentalidad',
    registerEmployeesRetirement: 'Jubilación',
    registerLackOfWork: 'Falta de trabajo',
    registerEmployeesDismissal: 'Despido',
    registerEmployeesOther: 'Motivo exacto del despido',
    registerEmployeesReturn: 'Cuantos trabajadores han regresado?',
    addJobButton: 'Añadir información para otro puesto',
    addJobNewSection: 'Total de empleados en otro lugar de trabajo',
    removeSectionButton: 'Remover sección',

    fileGovermentTab1Title: 'Publicaciones de trabajo',
    fileGovermentTab2Title: 'Documentos adicionales a adjuntar',
    companyTabFilesTitle: 'Archivos de Gobierno',
    fileGovermentTableColumn1: 'Llenar archivo',
    fileGovermentTableColumn2: 'Tipo de documento a descargar',
    fileGovermentTableColumn3: 'Nombre del archivo subido',
    fileGovermentTableColumn4: 'Acciones',
    fileGovermentConsentImportant: 'IMPORTANTE',
    fileGovermentConsent: `Es fundamental que todas las publicaciones permanezcan activas
    mientras dure el proceso de contratación, es decir, hasta que se reciban las aceptaciones.
    gobiernos federal y provinciales. Por favor, asegúrese de renovarlos antes de que caduquen.`,
    fileGovermentConsentCheckbox: 'He Entendido',
    fileGovermentLowSalaries: 'Puestos de bajos salarios',
    fileGovermentHighSalaries: 'Puestos bien pagados',
    fileGovermentExemption: 'Declaraciones C: Ver exención - No se requiere vista',
    fileAdditionalFiles: 'Prueba de capacidad financiera',

    Yes: 'SI',
    No: 'NO',
    createUser: 'Crear usuario para la compañia',
    accessDeniedPage: 'No tienes permiso para acceder a esta página...',

    loginEmail: 'Correo electrónico',
    loginPassword: 'Contraseña',
    loginBtnLabel: 'Ingresar',
    loginBtnAuthLabel: 'Autenticando...',
    loginForgotPasswordLabel: 'Olvidaste tu contraseña, Recuperala ',
    loginForgotPasswordLinkLabel: 'aquí',
    forgotPasswordBtn: 'Recuperar contraseña',
    forgotPasswordInProcessBtn: 'En proceso...',
    forgotPasswordBackLoginLabel: 'Regresar al ',
    forgotPasswordBackLoginLinkLabel: 'login',
    loginCopyRight: 'Todos los derechos reservados',
    loginNeedUser: 'No tienes un usuario? Registrate ',
    loginHelpValidateAccount: 'Necesitas ayuda para validar la cuenta? Haz click ',
    forgetPasswordMessageToResetPassword: 'Un mensaje le ha sido enviado a su correo para resetear su contraseña',
    registerName: 'Nombres',
    registerLastName: 'Apellidos',
    registerPhone: 'Teléfono',
    registerJobApply: 'Puesto al que aplica',
    registerConfirmPassword: 'Confirmar contraseña',
    registerInProcessLabel: 'Registrando usuario...',
    registerBtnLabel: 'Registrarse',
    registerAlreadyHaveUser: 'Ya estas registrado? Accede ',
    registerWelcomeMessage: 'Gracias por registrarse, un correo le ha sido enviado para verificar su cuenta',
    validateBtnLabel: 'Validar cuenta ',
    validateConfirmMessage: 'Un mensaje le ha sido enviado al adminitrador del sistema para ayudarlo con la validación de la cuenta',

    exitWithoutSave: 'Hay datos que no has guardado, ¿quieres salir sin guardar?',

    errorWhenSave: 'Sucedio un error al guardar los datos.',
    errorWhenLoad: 'Sucedio un error al cargar los datos.',
    errorWhenDelete: 'Sucedio un error al borrar los datos.',
    successWhenSave: 'Los datos se guardaron correctamente',
    successWhenDelete: 'Los datos se eliminaron correctamente',
    errorWhenLoadMessages: 'Sucedió un error al cargar los mensajes',
    errorWhenEnableTest: 'Sucedió un error al habilitar la prueba',
    successWhenEnableTest: 'La prueba se activo correctamente para el usuario',
    enableTermanTest: 'Habilitar la prueba de Terman',
    enableDiscTest: 'Habilitar la prueba de DISC',
    successWhenDeleteTest: 'La prueba se eliminó correctamente',
    askEnalbeTest: 'Esta seguro que desea habilitar la prueba?',
    askDeleteTest: 'Esta seguro que desea borrar la prueba?',
    deleteTestForApplicant: 'Borrar la prueba para este candidato',
    errorWhenSaveTest: 'Sucedió un error al guardar los datos de la prueba',
    errorToStartTest: 'Sucedió un error al intentar iniciar la prueba',
    errorWhenDeleteFile: 'Sucedió un error al tratar de borrar el archivo',
    successWhenDeleteFile: 'El archivo se eliminó correctamente',
    errorWhenLoadFiles: 'Sucedió un error al cargar los archivos',
    errorWhenSentMail: 'Sucedió un error al enviar el correo',
    successWhenSentEmail: 'El correo se envio satisfactoriamente',
    allowOnlyPdf: 'Solo se permiten archivos PDF',
    errorWhenLoadMails: 'Sucedió un error al cargar los correos',
    userNeedLoginAgain: 'La contraseña ha sido actualizada, el usuario debe volver a loguearse',
    userSavedAndNeedLoginAgain: 'Sus datos han sido actualizados, si cambió su contraseña tiene que volver a loguearse',
    userProvideEmail: 'Debe ingresar el correo',
    userProvideEmailOrSelect: 'Seleccione un usuario o agregue un correo y su contraseña',
    userProvideThePassword: 'Debe ingresar la contraseña',
    userProvideCurrentPassword: 'Debe ingresar la contraseña actual',
    userProvidePassword: 'Debe ingresar la nueva contraseña',
    userConfirmPassword: 'Debe confirmar la nueva contraseña',
    userPasswordDontMatch: 'Las contraseñas no coinciden.',
    userProvideToken: 'Debe ingresar el token que fue enviado a su correo o solicite uno nuevo',
    userDeleteSuccess: 'El usuario se eliminó correctamente',
    errorWhenRequestToken: 'Sucedió un error al solicitar el token',
    codeSentToEmail: 'Se le ha enviado un código a su correo',
    userErrorWhenValidate: 'Sucedió un error al validar el usuario',
    userSuccessWhenValidate: 'El usuario se validó correctamente',
    userDeleteAllData: 'Borrar todos los datos del usuario',
    userAskToDelete: 'Esta seguro que desea borrar el usuario y todos sus datos?',
    userValidateManual: 'Validar el usuario manualmente',
    userAskValidateManual: 'Esta seguro que desea validar el usuario?',
    usersDeleteAllData: 'Borrar todos los datos de los usuarios seleccionados',
    usersAskToDelete: 'Esta seguro que desea borrar los usuarios y todos sus datos?',
    usersValidateManual: 'Validar los usuarios seleccionados manualmente',
    usersAskValidateManual: 'Esta seguro que desea validar los usuarios?',
    applicantAssociatedToCompany: 'Se han asociado los candidatos seleccionados a la compañia',
    errorWhenCreateUser: 'Sucedió un error al crear el usuario',
    successWhenCreateUser: 'Se creo el usuario satisfactoriamente',
    notAllowPermissionToAction: 'No tiene permiso para realizar esta accion',

    notifyAdmin: 'El administrador ha sido notificado que has llenado toda la informacion.',
    unknowError: 'Sucedio un error en el servidor, contactar al administrador del sistema.',
    associatedUser: 'Usuario',

    logoutSuccess: 'Hasta pronto...',

    pickerImageTitle: 'Cambiando imagen de perfil',
    pickerImageLegend: 'Por favor seleccione una opción para la foto:',
    pickerImageText1: 'Tomar imagen de mi galeria',
    pickerImageText2: 'Tomar imagen usando mi cámara',
    pickerImageText3: 'Cancelar',

    errorWhenAuthenticate: 'Sucedió un error al autenticarse',
    errorWhenChangePassword: 'Sucedió un error al cambiar la contraseña',
    errorWhenValidateAccount: 'Sucedió un error al validar la cuenta',
    validatingAccount: 'VALIDANDO LA CUENTA...',
    accountValidateSuccess: 'Su cuenta ha sido validada correctamente',
    validationSweetTitle: 'Validación de cuenta',
    validatePositionSelection: 'Debe seleccionar el puesto',
    errorWhenRegisterUser: 'Sucedió un error al registrar al usuario',
    selectLabel: 'Seleccione',
    errorWhenRequestPassword: 'Sucedió un error al solicitar el cambio de contraseña',
    resetPasswordSweetTitle: 'Reseteando contraseña',
    errorWhenRequestValidateAccount: 'Sucedió un error al solicitar la validación',
    fileTypeSelectedAlreadyExists: 'El tipo de archivo seleccionado ya existe',
    notSelectedFileInLine: 'No ha seleccionado el archivo en la linea ',
    notSelectedFileTypeInLine: 'No ha seleccionado el tipo de archivo en la linea ',
    candidateAddedToNewFase: 'Se agregaron a los candidatos en la nueva fase',
    selectFase: 'Seleccionar la fase',
    changeFase: 'Cambiar de fase',
    changeTagColor: 'Se agrego un color de etiqueta a los candidatos seleccionados',
    addTagColor: 'Agregar un color de etiqueta',
    chooseTagColor: 'Elegir etiqueta',

    downloadAppTitle: 'Descargue la aplicación Travailleur Sans Frontière para Android™ o IOS',
};

export default Lang;
